/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import * as Sentry from "@sentry/remix";

// Initialize Sentry for the client side
Sentry.init({
  dsn: "https://23ba1e600bb6c1e8c669095c73f5192f@o4508258547990528.ingest.us.sentry.io/4508258549563392", // Replace with your actual DSN
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ useEffect, useLocation, useMatches }),
    Sentry.replayIntegration(), // Optional: Enables Session Replay
  ],
  tracesSampleRate: 1.0, // Adjust in production for performance
  replaysSessionSampleRate: 0.1, // Optional: Adjust as needed
  replaysOnErrorSampleRate: 1.0, // Optional: Adjust as needed
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
